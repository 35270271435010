import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <h1>Hello, world!</h1>
        <p>Imagine if this was the best external audit app ever!</p>
      </div>
    );
  }
}
